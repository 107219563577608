/**------------------------------------------------*
 * 【フローティングバナー制御JS】
 *  floating_banners.js   NNC 2017
 *-------------------------------------------------*/
/** Main
---------------------------------------------------*/
var isPcEnable = true; // PCでの機能抑制（true: 表示/false: 非表示）

$(function() {
	// フローティングバナーのイニシャライズ
	init_floatingBanners('.floating_banners');
});

/** Functions
---------------------------------------------------*/

/* フローティングバナーセットアップ
---------------------------------------------------*/
function init_floatingBanners(wrapper) {
	//if (document.cookie) {alert(document.cookie  + '\n\n' + parseInt((new Date).getTime() / 1e3));}

	var $wrapper = $(wrapper),
		usable = $(wrapper).length ? true : false,
		key;

	if (usable) {
		key = ($wrapper.data('key')) ? $wrapper.data('key') : 'bnr';
		//recentTime = (getCookie(key)) ? (isNaN(parseInt(getCookie(key), 10))) ? 0 : parseInt(getCookie(key), 10) : false,
		var	currentTime = parseInt((new Date).getTime() / 1e3, 10),
			pastTime = 86400,
			showEnable = true;

		var recentTime = 0;
		if (getCookie(key)) {
			if (isNaN(parseInt(getCookie(key), 10))) {
				recentTime = 0;
			} else {
				recentTime = parseInt(getCookie(key), 10);
			}
		} else {
			false;
		}

		if (recentTime) {
			pastTime = currentTime - recentTime;
			showEnable = (pastTime >= 86400) ? true : false;
		} else {
			showEnable = true;
		}
		if (showEnable) showBanners();
	}

	/* バナー表示
	---------------------------------------------*/
	function showBanners() {
		if (enableOnPcChk(isPcEnable)) {
			$wrapper.fadeIn('slow', 'linear', function() {
				init_bannerCloseBtn(key);	// 「閉じる」ボタン登録
				// バナークリック遷移後は24時間バナーを表示させない。
				/*$(this).find('a').on('click', function() {	// バナークリック
					setCloseTime();
				});*/
			});
		}
	}

	/* 「閉じる」ボタン登録
	---------------------------------------------*/
	function init_bannerCloseBtn(key) {
		$wrapper.find('.btn-close').on('click', function() {
			// setCloseTime();
			$wrapper.hide();
		});
	}

	/* バナークローズ時間記録
	---------------------------------------------*/
	function setCloseTime() {
		var cd = parseInt((new Date()).getTime() / 1e3),
			limit = 31;
		setCookie(key, cd, limit);
	}
}

/* PCでの表示抑制チェック
---------------------------------------------------*/
function enableOnPcChk(flag) {
	return (!flag && !navigator.userAgent.match(/iphone|android|mobile/i)) ? false : true;
}

/* Cookie操作
---------------------------------------------------*/
function setCookie(name, val, limitDay) {
	var exdate = new Date(new Date().getTime() + limitDay * 86400000);
	exdate = exdate.toGMTString();

	// pathの指定
	var path = location.pathname;
	// pathをフォルダ毎に指定する場合のIE対策
	var paths = new Array();
	paths = path.split('/');
	if (paths[paths.length - 1] !== '') {
		paths[paths.length - 1] = '';
		path = paths.join('/');
	}

	document.cookie = name + '=' + val + '; path=' + path + '; expires=' + exdate + '; ';
}

function getCookie(name) {
	var c = (document.cookie) ? document.cookie.replace(/\s/g, '').split(';') : [];
	if (c.length > 0) {
		for (i in c) {
			var r = new RegExp('^' + name + '=');
			if (c[i].match(r)) {
				var val = c[i].split('=')[1];
				return val;
				break;
			} else if (i >= c.length - 1) {
				return false;
				break;
			}
		}
	} else {
		return false;
	}
}
